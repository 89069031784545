// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-stories-archive-js": () => import("../src/templates/stories-archive.js" /* webpackChunkName: "component---src-templates-stories-archive-js" */),
  "component---src-templates-single-story-js": () => import("../src/templates/single-story.js" /* webpackChunkName: "component---src-templates-single-story-js" */),
  "component---src-templates-single-product-js": () => import("../src/templates/single-product.js" /* webpackChunkName: "component---src-templates-single-product-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-gallery-js": () => import("../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-store-js": () => import("../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */)
}

